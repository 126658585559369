import React from 'react'
import { Switch, Route } from 'react-router-dom'
// import { Switch, Route, Redirect } from 'react-router-dom'

import Home from './views/Home'
import AboutUs from './views/AboutUs'
import Tariff from './views/Tariff'
import FAQ from './views/Faq'
import Terms from './views/Terms'
import Login from './views/Login'
import Contact from './views/Contact'
import Thanks from './views/Thanks'
import Error from './views/Error'
// import CallbackWithdrawalRecharge from './views/api/CallbackWithdrawalRecharge'
// import Api from './views/Api'
// import CreateUser from './views/api/CreateUser'
// import AuthenticateYourUserAndGetToken from './views/api/AuthenticateYourUserAndGetToken'
// import Authentication from './views/api/Authentication'
// import GetCep from './views/api/GetCep'
// import RechargeInformationRetrieve from './views/api/RechargeInformationRetrieve'
// import RechargeCallback from './views/api/RechargeCallback'
// import BrazilianBanksRetrieve from './views/api/BrazilianBanksRetrieve'
// import WithdrawalTransactionRetrieve from './views/api/WithdrawalTansactionRetrieve'
// import WithdrawalCallback from './views/api/WithdrawalCallback'
// import OnlinePix from './views/api/OnlinePix'
// import RegisterUser from './views/api/RegisterUser'
// import KycGetRegisterUser from './views/api/KycGetRegisterUser'
// import Ted from './views/api/Ted'


export default function Routes(){
    // function isLoggedIn(){
    //     const status = sessionStorage.getItem('signedin');
    //     return status;
    // }
    // function Protected(){
    //     return (
    //         isLoggedIn() === '1' ? <Route path='/loggedin' render={(props) => <Loggedin {...props} />}/> : <Redirect to='/login'/>
    //     )
    // }
        
    return(
        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/about-us' component={AboutUs} />
            <Route path='/tariff' component={Tariff} />
            <Route path='/faq' component={FAQ} />
            <Route path='/terms-of-use' component={Terms} />
            <Route path='/login' component={Login}/>
            <Route path='/contact' component={Contact}/>
            <Route path='/thanks' component={Thanks}/>
            <Route path='/error' component={Error}/>
            {/* <Route path='/api/callbackwithdrawalrecharge' component={CallbackWithdrawalRecharge}/> */}
            {/* <Route path='/api/createuser' component={CreateUser} />
            <Route path='/api/authenticateyouruserandgettoken' component={AuthenticateYourUserAndGetToken} />
            <Route path='/api/getcep' component={GetCep} />
            <Route path='/api/authentication' component={Authentication} />
            <Route path='/api/rechargeinformationretrieve' component={RechargeInformationRetrieve} />
            <Route path='/api/rechargecallback' component={RechargeCallback} />
            <Route path='/api/brazilianbanksretrieve' component={BrazilianBanksRetrieve} />
            <Route path='/api/withdrawaltransactionretrieve' component={WithdrawalTransactionRetrieve} />
            <Route path='/api/withdrawalcallback' component={WithdrawalCallback} />  
            <Route path='/api/onlinepix' component={OnlinePix} />
            <Route path='/api/registeruser' component={RegisterUser} />
            <Route path='/api/getregistereduserskyc' component={KycGetRegisterUser} />
            <Route path='/api/ted' component={Ted} /> */}

            {/* It's very important to keep /api as the last option for the /api routes */}
            {/* <Route path='/api' component={Api} /> */}

            <Route path='/api' component={() => { 
                window.location.href = 'https://doc.brainpay.com.br/'; 
                return null;
            }}/>

            {/* <Route path='/login' render={(props) => <Login {...props} />} /> */}
            {/* <Protected></Protected> */}
        </Switch>
    )
}