import React, {useState} from 'react'
import {useTranslation} from "react-i18next"
import Background from '../assets/background-black.png'
import Wave from '../components/Wave'
import ImgCode from '../assets/code.png'
import { Link } from 'react-router-dom'

function HomeApi () {
    
    // change incon
    const [showText, setShowText] = useState(false)
    let icon = showText ? "fas fa-minus" : "fas fa-plus"

    // Translation
    const {t} = useTranslation()

    return (
        <>
        <Wave/>
        <div>
            <div className="bg-animation">
                <img className="zoom-fade" src={Background} alt=""/>
            </div>
            <div className="container-home-api">
                <div className="section-title mb-0 md-mb-3">
                    <div className="title-effect title-effect-2">
                        <div className="ellipse"></div>
                            <i className="fas fa-cubes"></i>
                    </div>
                </div>
                <div className="home-api">
                    <div className="home-api-infomation">
                        <h2>{t('home-api.integrate')}</h2>
                        <button onClick={() => setShowText(!showText)}>{t('home-api.api')}<i className={icon}></i></button>
                        {showText && <div className="link-api">{t('home-api.api-info')}<Link to='/api'>{t('home-api.api-link')}</Link></div>}
                </div>
                    <div className="home-api-img">
                        <div className="img">
                            <img src={ImgCode} alt="API"></img> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default HomeApi
