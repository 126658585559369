import React from 'react'
import Header from '../components/Header'
import HomeTop from '../components/HomeTop'
import HomeApi from '../components/HomeApi'
import Carousel from '../components/Carousel'
import UsedBy from '../components/UsedBy'
import Footer from '../components/Footer'

function Home () {

    return (
        <>
        <Header/>
        <main>
            <section>
                <HomeTop/>
            </section>
            <section>
                <HomeApi/>
            </section>
            <section className="container-carousel">
                <Carousel/>
            </section>
            <section>
                <UsedBy/>
            </section>
        </main>
        <Footer check={true}/>
        </>
    )
}

export default Home

