import React from 'react'
import '../i18n'
import {useTranslation} from "react-i18next"


export let language = 'en'

export function SelectLanguages () {

    // functions to change Language
    const changeLanguage = (le) => {
        return i18n.changeLanguage(le)
    }

    const handleEvent = (e) => {
        changeLanguage(e.target.value)
        language = e.target.value
    }

    const {i18n} = useTranslation()

    return (
        <select onChange={e => handleEvent(e)} value={language}>
            <option value='en'>English</option>
            <option value='pt'>Portuguese</option>
            <option value='ch'>Chinese</option>
        </select>
    )
}
