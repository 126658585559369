import React from 'react'
import {useTranslation} from "react-i18next"
import Header from '../components/Header'
import Footer from '../components/Footer'

const AboutUs = () => {

    const {t} = useTranslation()

    return (
        <>
            <Header/>
            <main className="container"> 
                <section className="container-text">
                    <h1>{t('about_us.title')}</h1>
                    <div>
                        <p>{t('about_us.paragraph_1')}</p>
                        <p>{t('about_us.paragraph_2')}</p>
                        <p>{t('about_us.paragraph_3')}</p>
                        <p>{t('about_us.paragraph_4')}</p>
                    </div>
                </section>
            </main>
            <Footer check={false}/>
        </>
    )
}

export default AboutUs
