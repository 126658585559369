import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/brain-logo.png'
import NavBar from './NavBar'
import DropdownMenu from './DropdownMenu'
import {useTranslation} from "react-i18next"
import {SelectLanguages} from './SelectLanguages'

function Header () {
    
    // class to change Header
    const [header , setHeader] = useState('header')

    const changeClassHeader = () => {
        if(window.scrollY <= 0) {
            setHeader('header')
        }else {
            setHeader('fixed-header')
        }
    }

    window.addEventListener('scroll', changeClassHeader)

    // Translation to "Acessar Conta"
    const {t} = useTranslation()

    return (
        <header className={header}>
            <div>
                <Link to='/'><img src={Logo} className="logo" alt='BrainPay'/></Link>
            </div>
            <div>
                <NavBar/>
                <DropdownMenu/>
            </div>

            <SelectLanguages></SelectLanguages>

            <div className="account">
                <Link to='/'><button className="navbar-link nav-item-header">{t('header.acessar_conta')}</button></Link>
            </div>
        </header>
    )
}

export default Header
