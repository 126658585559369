import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next"
import SignUp from './signUp'

// TODO: Links on App Store Icons

function Footer (props) {

    // Translation
    const {t} = useTranslation()
    
    // showSignUp
    const [showSignUp, setShowSignUp] = useState(true)
    let check = props.check

    useEffect(() => {
        
        if (check === false) {
            setShowSignUp(false)
        }
    },[check]);

    return (
        <>
        {showSignUp && <SignUp/>}
        <footer>
            <div className="subscribe-footer">
                <div className="subscribe-footer-info">
                    <p className="text">{t('footer.info')}</p>
                    <Link to="/api"><button src="/api">{t('footer.button')}</button></Link>
                </div>
                <div>
                    <ul>
                        <li>{t('footer.first-li.about-us')}</li>
                        <li>{t('footer.first-li.jobs')}</li>
                        <li>{t('footer.first-li.press-kit')}</li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li><i className = "fab fa-facebook"></i> {t( 'footer.font_awesome.facebook')}</li>
                        <li><i className = "fab fa-twitter"></i> {t( 'footer.font_awesome.twiter')}</li>
                        <li><i className = "fab fa-instagram"></i> {t( 'footer.font_awesome.instagram')}</li>
                        <li><i className = "fab fa-linkedin-in"></i> {t( 'footer.font_awesome.linkedin')}</li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>{t('footer.second-li.privacy_policy')}</li>
                        <li>{t('footer.second-li.terms')}</li>
                        <li>{t('footer.second-li.customer_support')}</li>
                        <li>{t('footer.second-li.talk_sales')}</li>
                    </ul>
                </div>
            </div>
                <p className = "center">{t('footer.copyright')}</p>
        </footer>
    </>
    )
}

export default Footer