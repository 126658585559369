import React from 'react'
import {useTranslation} from "react-i18next"
import ImgOne from '../assets/aave.png'
import ImgTwo from '../assets/metamask.png'
import ImgThree from '../assets/klever.png'
import ImgFour from '../assets/matic.png'
import Background from '../assets/background-black.png'

// TODO change alt

function UsedBy () {

    const {t} = useTranslation()
    
    return (
        <div>
            <div className="bg-animation-usedby">
                <img className="zoom-fade-usedBy" src={Background} alt=""/>
            </div>
            <div className='container'>
                <div className="used-by">
                    <div className="col-lg-6 col-md-10 ml-auto mr-auto">
                        <div className="section-title-usedby">
                            <div className="title-effect-usedby">
                                <div className="bar white-bar bar-top"></div>
                                <div className="bar white-bar bar-right"></div>
                                <div className="bar white-bar bar-bottom"></div>
                                <div className="bar white-bar bar-left"></div>
                            </div>
                                <h6 className="used-by-text">{t('used-by')}</h6>
                        </div>
                    </div>
                    <div className="used-by-img">
                        <div>
                            <img src={ImgOne} alt='partner one'/>
                        </div>
                        <div>
                            <img src={ImgTwo} alt='partner two'/>
                        </div>
                        <div>
                            <img src={ImgThree} alt='partner three'/>
                        </div>
                        <div>
                            <img src={ImgFour} alt='partner four'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsedBy

