import React from 'react'
import {useTranslation} from "react-i18next"
import Cell from '../assets/hometop-celular.png'
import Apple from '../assets/hometop-apple-icon.png'
import Google from '../assets/hometop-google-icon.png'

// TODO: Links on App Store Icons

function HomeTop () {

    const {t} = useTranslation()
    
    return (
        <div className='container home-top'>
            <div className='home-top-information'>
                <h1 className="page-title">{t('home-top.introduce')}</h1>
                <div>
                <p className="lead">{t('home-top.download')}</p>
                </div>
                <div className='download-container-992px'>
                    <div className='download'>
                        <img  alt='App Store' src={Apple} />
                    </div>
                    <div className='download'>
                        <img alt='Google Play' src={Google} />
                    </div>
                </div>
            </div>
            <div>
                    <img className="home-top-cell" alt='Simulador' src={Cell} />
                <div className='download-container'>
                    <div>
                        <img  alt='App Store' src={Apple} />
                    </div>
                    <div>
                        <img alt='Google Play' src={Google} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeTop;