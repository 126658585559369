import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {useTranslation} from "react-i18next"
import ImgOne from '../assets/fiatOnRamp.svg'
import ImgTwo from '../assets/developer.svg'
import ImgThree from '../assets/non-custodial.svg'

// TODO: Change alt

function ComponentCarousel () {
  
    // CAROUSEL
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 500, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    // TRANSLATION
    const {t} = useTranslation()

    return (
      <>
        <Carousel
            className='my-own-custom-container'
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <div className="carousel-box">
                <img src={ImgOne} alt="info1"/>
                <h5>{t('carousel.title_one')}</h5>
                <p>{t('carousel.info_one')}</p>
            </div>
            <div className="carousel-box carousel-box-992px">
                <img src={ImgTwo} alt="info1"/>
                <h5>{t('carousel.title_two')}</h5>
                <p>{t('carousel.info_two')}</p>
            </div>
            <div className="carousel-box">
                <img src={ImgThree} alt="info1"/>
                <h5>{t('carousel.title_three')}</h5>
                <p>{t('carousel.info_three')}</p>
            </div>
        </Carousel>
        </>
    )
}

export default ComponentCarousel