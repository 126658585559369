import React from 'react'
import {useTranslation} from "react-i18next"
import Header from '../components/Header'
import Footer from '../components/Footer'

const Tariff = () => {

    const {t} = useTranslation()
    
    return (
        <>
        <Header/>
        <main className="container"> 
                <section className="container-text">
                    <h1>{t('Tariff.paragraph_1')}</h1>
                </section>
            </main>
        <Footer check={false}/>
        </>
    )
}

export default Tariff
