import React, {useState, useEffect} from 'react'
import {useTranslation} from "react-i18next"
import Spinner from 'react-spinner-material';
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';


const Contact = () => {

    //CHANGE LENGUAGES
    const {t} = useTranslation()

    // VARIABLES
    const [submitted, setSubmitted] = useState(false);
    const [regexEmail, setRegexEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({
        email: '',
        subject : '',
        message: ''
    })


    //Select options variables and map
    let language = useTranslation().i18n.language
    let [options, setOptions] = useState(["Choose a Subject","More information about BrainPay", "Partnerships", "Work with us", "Other subject"])
    const Add = options.map(Add => Add)


    //FUNCTIONS
    function selectSubject (language) {
        if (language === 'en' || language === 'ch' ) {
            return setOptions(["Choose a Subject","More information about BrainPay", "Partnerships", "Work with us", "Other subject"])
        } else if (language === 'pt') {
           return setOptions(["Escolha um Assunto", "Mais infromações sobre BrainPay", "Parcerias", "Trabalhe Conosco", "Outros Assuntos"])
        } 
    }

    function validateEmail(event) {
        event.persist();
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(event.target.value).toLowerCase())){
            handleEmailInputChange(event.target.value)
            setRegexEmail(true)
        }
    }

    const handleEmailInputChange = (email) => {
            setFields((fields) => ({
                ...fields,
                email: email,
            }))
    };

    const handleSubjectInputChange = (event) => {
        event.persist();
        if (event.target.value !== "0") {
            setFields((fields) => ({
                ...fields,
                subject: event.target.value,
            }))
        }else {
            setFields((fields) => ({
                ...fields,
                subject: '',
            }))
        }
    };
    
    const handleMessageChange = (event) => {
        event.persist();
        setFields((message) => ({
            ...message,
            message: event.target.value,
        }))
    };


    function handleFormSubmit(event){
        event.preventDefault()
        if (fields.email && fields.subject && fields.message) {
            setRegexEmail(true)
            setSubmitted(true)
            send()

        }
        setSubmitted(true);

    }

    // Create the field (improve message and put subject text)
    function field() {
        setLoading(true)
        fields.message = `${fields.message}   The client e-mail is: ${fields.email}`
        if (fields.subject === '0'){
            fields.subject = ''
        } else if (fields.subject === '1') {
            fields.subject = 'More information about BrainPay'
        } else if (fields.subject === '2') {
            fields.subject = 'Partnerships'
        } else if (fields.subject === '3') {
                fields.subject = 'Work with us'
        } else if (fields.subject === '4') {
            fields.subject = 'Other subject'
        }
    }

    // send field to backend
    const send = async () => {
        field()
        const formData = new FormData()
        Object.keys(fields).forEach( key => formData.append(key, fields[key]))
        await axios.post('https://office.brainpay.com.br/companies/email/', fields, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(function (response) {
            if(response.data.code === 200){
                window.location.href = "/thanks"
            } else {
                window.location.href = "/error"
            }
          })
        .catch(function (error) {
            window.location.href = "/error"
          })
    }


    useEffect(() => {

        selectSubject(language)

    },[language]);

    return (
        <>
        <Header/>
        <main className="container"> 
                {loading ? <div className="lodding-contact"><Spinner radius={120} color={"#447FD9"} stroke={8} visible={true} /></div> :
                <section className="contact">
                <div>
                    <h1>{t('contact.title')}</h1>
                </div>
                    <form onSubmit = {handleFormSubmit}>

                        <input type="text" name="email" placeholder={t('contact.email')} onChange = {validateEmail}/>
                        <div className="error-div">
                            {submitted && !regexEmail && <span className='erro-contact'>{t('contact.erro_email')}</span>} 
                        </div>


                        < select onChange={e => handleSubjectInputChange(e)}>
                            {
                                Add.map((option, key) => <option value={key}>{option}</option>)
                            }
                        </select >
                        <div className="error-div">
                            {submitted && !fields.subject && <span className='erro-contact'>{t('contact.erro_subject')}</span>} 
                        </div>
                                

                        <textarea  name="message" onChange = {handleMessageChange} value={fields.message} placeholder={t('contact.message')} className="textArea"></textarea>
                        <div className="error-div">
                            {submitted && !fields.message && <span className='erro-contact'>{t('contact.erro_message')}</span>}
                        </div>

                        <button type="submit" value="Enviar">{t('contact.button')}</button>
                    </form>
                </section> }
            </main>
        <Footer check={false}/>
        </>
    )
}

export default Contact
