import React, {useRef, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import {useTranslation} from "react-i18next"

function DropdownMenu () {
  
    const dropdownRef = useRef(null)
    const [isActive, setIsActive] = useState(false)

    // BURGER USESTATE
    const [setOpen] = useState(false)
  
    // MENU USESTATE
    const onClick = () => setIsActive(!isActive)

    useEffect(() => {
        const pageClickEvent = (e) => {
            // If the active element exists and is clicked outside of
            if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
              setIsActive(!isActive);
            }
        };
      
        // If the item is active (ie open) then listen for clicks
        if (isActive) {
          window.addEventListener('click', pageClickEvent)
        }
      
        return () => {
          window.removeEventListener('click', pageClickEvent)
        }
      
    }, [isActive]);

    // TRANSLATION
    const {t} = useTranslation()
  
    return (
        <div className="dropdown">
            <Hamburger className="menu-trigger" color="#2B7CFA" direction="right" duration={0.5} onToggle={(setOpen, onClick)} />
        <div className="dropdown-menu">
            <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
               <ul>
                <li><Link to='/about-us'><button className="navbar-link"><span>{t('header.about_us')}</span></button></Link></li>
                <li><Link to='/faq'><button className="navbar-link"><span>{t('header.faq')}</span></button></Link></li>
                <li><Link to='/api'><button className="navbar-link"><span>{t('header.api')}</span></button></Link></li>
                <li><Link to='/tariff'><button className="navbar-link"><span>{t('header.tariff')}</span></button></Link></li>
                <li><Link to='/terms-of-use'><button className="navbar-link"><span>{t('header.terms_of_use')}</span></button></Link></li>
                <li><Link to='/contact'><button className="navbar-link">{t('header.contact')}</button></Link></li>
                <li><Link to='/'><button className="navbar-link"><span>{t('header.acessar_conta')}</span></button></Link></li>
               </ul>
            </nav>
        </div>
        </div>
    )
}

export default DropdownMenu
