import React from 'react'
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next"
import Header from '../components/Header'
import Footer from '../components/Footer'

const Error = () => {

    const {t} = useTranslation()
    
    return (
        <>
        <Header/>
        <main className="container"> 
                <section className="erro">
                    <div>
                        <i className="fas fa-exclamation-triangle fa-5x"></i>
                    </div>
                    <div>
                        <h1>{t('erro.erro')}</h1>
                    </div>
                    <div>
                        <h2>{t('erro.paragraph_1')}</h2>
                        <p>{t('erro.paragraph_2')}<Link to='/'>BrainPay</Link></p>
                    </div>
                </section>
            </main>
        <Footer check={false}/>
        </>
    )
}

export default Error