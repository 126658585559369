import React from 'react'
import {useTranslation} from "react-i18next"
import Header from '../components/Header'
import Footer from '../components/Footer'

const Terms = () => {

    // Translation
    const {t} = useTranslation()

    return (
        <>
        <Header/>
        <main className="container"> 
            <section className="container-text">
                <h1>{t('terms.title')}</h1>
                <div>
                <p>{t('terms.paragraph_1')}</p>
                    <p>{t('terms.paragraph_2')}</p>
                    <p>{t('terms.paragraph_3')}</p>
                    <p>{t('terms.paragraph_4')}</p>
                    <p>{t('terms.paragraph_5')}</p>
                    {/* Definitions */}
                    <ol className='dot'>
                        <li className='number'>{t('terms.definitions.title')}</li>
                        <p>{t('terms.definitions.paragraph_1')}</p>
                        <p>{t('terms.definitions.paragraph_2')}</p>
                        <p>{t('terms.definitions.paragraph_3')}</p>
                        <p>{t('terms.definitions.paragraph_4')}</p>
                        <p>{t('terms.definitions.paragraph_5')}</p>
                    {/* About_app */}
                        <li className='number'>{t('terms.about_app.title')}</li>
                        <p>{t('terms.about_app.paragraph_1')}</p>
                        <p>{t('terms.about_app.paragraph_2')}</p>
                        <p>{t('terms.about_app.paragraph_3')}</p>
                        <p>{t('terms.about_app.paragraph_4')}</p>
                        <p>{t('terms.about_app.paragraph_5')}</p>
                        <p>{t('terms.about_app.paragraph_6')}</p>
                        <p>{t('terms.about_app.paragraph_7')}</p>
                        <p>{t('terms.about_app.paragraph_8')}</p>
                    {/* Eligibility */}
                        <li className='number'>{t('terms.eligibility.title')}</li>
                        <p>{t('terms.eligibility.paragraph_1')}</p>
                        <p>{t('terms.eligibility.paragraph_2')}</p>
                        <p>{t('terms.eligibility.paragraph_3')}</p>
                        <p>{t('terms.eligibility.paragraph_4')}</p>
                        <p>{t('terms.eligibility.paragraph_5')}</p>
                    {/* Register */}
                        <li className='number'>{t('terms.register.title')}</li>
                        <p>{t('terms.register.paragraph_1')}</p>
                        <p>{t('terms.register.paragraph_2')}</p>
                        <p>{t('terms.register.paragraph_3')}</p>
                        <ol className='letter'>
                            <li>{t('terms.register.li_1')}</li>
                            <li>{t('terms.register.li_2')}</li>
                            <li>{t('terms.register.li_3')}</li>
                            <li>{t('terms.register.li_4')}</li>
                            <li>{t('terms.register.li_5')}</li>
                            <li>{t('terms.register.li_6')}</li>
                            <li>{t('terms.register.li_7')}</li>
                            <li>{t('terms.register.li_8')}</li>
                        </ol>
                        <p>{t('terms.register.paragraph_4')}</p>
                        <p>{t('terms.register.paragraph_5')}</p>
                        <p>{t('terms.register.paragraph_6')}</p>
                        <p>{t('terms.register.paragraph_7')}</p>
                        <p>{t('terms.register.paragraph_8')}</p>
                        <p>{t('terms.register.paragraph_9')}</p>
                    {/* Register */}
                        <li className='number'>{t('terms.conduct.title')}</li>
                        <p>{t('terms.conduct.paragraph_1')}</p>
                        <ol className='letter'>
                            <li>{t('terms.conduct.li_1')}</li>
                            <li>{t('terms.conduct.li_2')}</li>
                            <li>{t('terms.conduct.li_3')}</li>
                            <li>{t('terms.conduct.li_4')}</li>
                            <li>{t('terms.conduct.li_5')}</li>
                            <li>{t('terms.conduct.li_6')}</li>
                            <li>{t('terms.conduct.li_7')}</li>
                            <li>{t('terms.conduct.li_8')}</li>
                            <li>{t('terms.conduct.li_9')}</li>
                            <li>{t('terms.conduct.li_10')}</li>
                        </ol>
                    {/* Laws */}
                        <li className='number'>{t('terms.laws.title')}</li>
                        <p>{t('terms.laws.paragraph_1')}</p>
                        <ol className='letter'>
                            <li>{t('terms.laws.li_1')}</li>
                            <li>{t('terms.laws.li_2')}</li>
                            <li>{t('terms.laws.li_3')}</li>
                            <li>{t('terms.laws.li_4')}</li>
                            <li>{t('terms.laws.li_5')}</li>
                            <li>{t('terms.laws.li_6')}</li>
                            <li>{t('terms.laws.li_7')}</li>
                            <li>{t('terms.laws.li_8')}</li>
                        </ol>
                            <p>{t('terms.laws.li_9')}</p>
                            <p>{t('terms.laws.li_10')}</p>
                    {/* Risk */}
                        <li className='number'>{t('terms.risk.title')}</li>
                        <p>{t('terms.risk.paragraph_1')}</p>
                        <p className="strong">{t('terms.risk.paragraph_2')}</p>
                        <p>{t('terms.risk.paragraph_3')}</p>
                        <p>{t('terms.risk.paragraph_4')}</p>
                        <p>{t('terms.risk.paragraph_5')}</p>
                        <p className="strong">{t('terms.risk.paragraph_6')}</p>
                        <p>{t('terms.risk.paragraph_7')}</p>
                        <p>{t('terms.risk.paragraph_8')}</p>
                        <p className="strong">{t('terms.risk.paragraph_9')}</p>
                        <p>{t('terms.risk.paragraph_10')}</p>
                        <p>{t('terms.risk.paragraph_11')}</p>
                        <p className="strong">{t('terms.risk.paragraph_12')}</p>
                        <p>{t('terms.risk.paragraph_13')}</p>
                        <p>{t('terms.risk.paragraph_14')}</p>
                        <p className="strong">{t('terms.risk.paragraph_15')}</p>
                        <p>{t('terms.risk.paragraph_16')}</p>
                        <p className="strong">{t('terms.risk.paragraph_17')}</p>
                        <p>{t('terms.risk.paragraph_18')}</p>
                        <p>{t('terms.risk.paragraph_19')}</p>
                        <p className="strong">{t('terms.risk.paragraph_20')}</p>
                        <p>{t('terms.risk.paragraph_21')}</p>
                        <p>{t('terms.risk.paragraph_22')}</p>
                        <p>{t('terms.risk.paragraph_23')}</p>
                        <p className="strong">{t('terms.risk.paragraph_24')}</p>
                        <p>{t('terms.risk.paragraph_25')}</p>
                        <p>{t('terms.risk.paragraph_26')}</p>
                        <p>{t('terms.risk.paragraph_27')}</p>
                    {/* Privacy */}
                        <li className='number'>{t('terms.privacy.title')}</li>
                        <p>{t('terms.privacy.paragraph_1')}</p>
                        <p>{t('terms.privacy.paragraph_2')}</p>
                        <p>{t('terms.privacy.paragraph_3')}</p>
                        <p>{t('terms.privacy.paragraph_4')}</p>
                        <p>{t('terms.privacy.paragraph_5')}</p>
                        <p>{t('terms.privacy.paragraph_6')}</p>
                        <p>{t('terms.privacy.paragraph_7')}</p>
                        <p>{t('terms.privacy.paragraph_8')}</p>
                        <p>{t('terms.privacy.paragraph_9')}</p>
                        <p>{t('terms.privacy.paragraph_10')}</p>
                        <p>{t('terms.privacy.paragraph_11')}</p>
                        <p>{t('terms.privacy.paragraph_12')}</p>
                        <p>{t('terms.privacy.paragraph_13')}</p>
                    {/* Cancel */}
                        <li className='number'>{t('terms.cancel.title')}</li>
                        <p>{t('terms.cancel.paragraph_1')}</p>
                        <ol className='letter'>
                            <li>{t('terms.cancel.li_1')}</li>
                            <li>{t('terms.cancel.li_2')}</li>
                        </ol>
                        <p>{t('terms.cancel.paragraph_2')}</p>
                        <ol className='roman'>
                            <li>{t('terms.cancel.paragraph_3')}</li>
                            <li>{t('terms.cancel.paragraph_4')}</li>
                        </ol>
                            <p>{t('terms.cancel.paragraph_5')}</p>
                            <p>{t('terms.cancel.paragraph_6')}</p>
                            <p>{t('terms.cancel.paragraph_7')}</p>
                            <p>{t('terms.cancel.paragraph_8')}</p>
                            <p>{t('terms.cancel.paragraph_9')}</p>
                            <p>{t('terms.cancel.paragraph_10')}</p>
                            <p>{t('terms.cancel.paragraph_11')}</p>
                        {/* Property*/}
                            <li className='number'>{t('terms.property.title')}</li>
                            <p>{t('terms.property.paragraph_1')}
                            </p>
                        {/* Refund*/}
                            <li className='number'>{t('terms.refund.title')}</li>
                            <p>{t('terms.refund.paragraph_1')}</p>
                        {/* Resignation*/}
                            <li className='number'>{t('terms.resignation.title')}</li>
                            <ol className='number'>
                                <p><li>{t('terms.resignation.paragraph_1')}</li></p>
                                <p><li>{t('terms.resignation.paragraph_2')}</li></p>
                                <p><li>{t('terms.resignation.paragraph_3')}</li></p>
                                <p><li>{t('terms.resignation.paragraph_4')}</li></p>
                                <p><li>{t('terms.resignation.paragraph_5')}</li></p>
                                <p><li>{t('terms.resignation.paragraph_6')}</li></p>
                                <p><li>{t('terms.resignation.paragraph_7')}</li></p>
                                <p><li>{t('terms.resignation.paragraph_8')}</li></p>
                            </ol>
                        {/* Changes*/}
                            <li className='number'>{t('terms.changes.title')}</li>
                            <p>{t('terms.changes.paragraph_1')}</p>
                            <p>{t('terms.changes.paragraph_2')}</p>
                            <p>{t('terms.changes.paragraph_3')}</p>
                            <p>{t('terms.changes.paragraph_4')}</p>
                        {/* Service*/}
                            <li className='number'>{t('terms.service.title')}</li>
                            <p>{t('terms.service.paragraph_1')}</p>
                        {/* Considerations*/}
                            <li className='number'>{t('terms.considerations.title')}</li>
                            <p>{t('terms.considerations.paragraph_1')}</p>
                            <p>{t('terms.considerations.paragraph_2')}
                            </p>
                        </ol>
                    </div>
                </section>
            </main>
            <Footer check={false}/>
        </>
    )
}

export default Terms
