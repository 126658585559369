import React from 'react'

function Login() {
    
    return (
        <div>
            <h1>Login</h1>
        </div>
    )
}

export default Login
