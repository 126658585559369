import React from 'react'
import {useTranslation} from "react-i18next"

function SignUp () {

    const {t} = useTranslation()
    
    return (
        <div className="subscribe-box">
            <div className="subscribe-inner">
                <div className="subscribe-inner-text">
                    <h4>{t('sign-up.title')}</h4>
                    <p className="text">{t('sign-up.info')}</p>
                </div>
                <div>
                    <form>
                        <label className="label" htmlFor="email">{t('sign-up.label')}<span>*</span></label>
                        <input type="email" id="email"/>
                        <p>{t('sign-up.required')}</p>
                        <button>{t('sign-up.subscribe')}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignUp