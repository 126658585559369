import React from 'react'
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next"

function NavBar () {

    const {t} = useTranslation()
    
    return (
        <nav className="navbar">
            <Link to='/about-us'><button className="navbar-link navbar-item-one">{t('header.about_us')}</button></Link>
            <Link to='/faq'><button className="navbar-link navbar-item-two">{t('header.faq')}</button></Link>
            <Link to='/api'><button className="navbar-link navbar-item-three">{t('header.api')}</button></Link>
            <Link to='/tariff'><button className="navbar-link navbar-item-four">{t('header.tariff')}</button></Link>
            <Link to='/terms-of-use'><button className="navbar-link navbar-item-five">{t('header.terms_of_use')}</button></Link>
            <Link to='/contact'><button className="navbar-link navbar-item-six">{t('header.contact')}</button></Link>
        </nav>
    )
}

export default NavBar
